import React from "react";
import { br, PageWrapper } from "../../components/Containers/PageWrapper";
import ImgWhy from "../../assets/images/sub/img-why.png";
import ImgWhyM from "../../assets/images/sub/img-why-m.png";
import ImgWhyBlog from "../../assets/images/sub/img-why-blog.jpg";
import ImgWhyFun1 from "../../assets/images/sub/img-why-foun1.jpg";
import ImgWhyFun1M from "../../assets/images/sub/img-why-foun1-m.jpg";
import ImgWhyFun2 from "../../assets/images/sub/img-why-foun2.jpg";
import ImgWhyFun2M from "../../assets/images/sub/img-why-foun2-m.jpg";
import ImgWhyFun3 from "../../assets/images/sub/img-why-foun3.jpg";
import ImgWhyFun3M from "../../assets/images/sub/img-why-foun3-m.jpg";
import ImgCi from "../../assets/images/sub/img-ci.png";
import ImgCiM from "../../assets/images/sub/img-ci-m.jpg";
import { FormattedMessage } from "react-intl";

export const About: React.FC = () => {
  return (
    <PageWrapper>
      <section className="sec-areatop about">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="About us" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="BOSagora" />
            </h2>
            <p className="txt">
              <FormattedMessage
                defaultMessage="BOSagora contains the vision of BOSagora to
                realize the birth of a true democracy
                by combining the words “BOS”, which represents the basic operating
                system of a computer, and “AGORA”, which symbolizes direct democracy."
              />
            </p>
          </div>
          <div className="btn-area">
            <a
              href="/files/BOSAGORA_Whitepaper_EN_Aug_2024.pdf"
              className="btn-g download white m1"
              target="_blank"
            >
              <FormattedMessage
                defaultMessage="{whitePaper} English version "
                values={{
                  whitePaper: (
                    <em>
                      <FormattedMessage defaultMessage="WhitePaper" />
                    </em>
                  ),
                }}
              />
              <span className="material-symbols-outlined">download</span>
            </a>
            <a
              href="/files/BOSAGORA_Whitepaper_KR_Aug_2024.pdf"
              className="btn-g download white m2"
              target="_blank"
            >
              <FormattedMessage
                defaultMessage="{whitePaper} Korean version "
                values={{
                  whitePaper: (
                    <em>
                      <FormattedMessage defaultMessage="WhitePaper" />
                    </em>
                  ),
                }}
              />
              <span className="material-symbols-outlined">download</span>
            </a>
          </div>
        </div>
      </section>
      <section className="sec-why bg-w">
        <div className="container">
          <ul className="lst-why">
            <li>
              <h3 className="sub-tit3-s">
                <FormattedMessage defaultMessage="Our mission" />
              </h3>
              <strong>
                <FormattedMessage
                  defaultMessage="Making a better world {br}with Blockchain
                  technology {br}as a project enabler."
                  values={br()}
                />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="Building an open decentralized blockchain
                  protocol that ensures {br} transparency of the consensus
                  algorithm and the clarity of contracts, {br} thereby
                  enriching the blockchain ecosystem through enabling meaningful
                  {br} projects with the expression of the collective intelligence
                  by {br} an advanced democratic decision-making process."
                  values={br()}
                />
              </p>
            </li>
            <li>
              <h3 className="sub-tit3-s">
                <FormattedMessage defaultMessage="Our Vision" />
              </h3>
              <strong>
                <FormattedMessage
                  defaultMessage="Never stopped seeking {br}
                  fruitful development achievements {br}
                  and revitalizing our community."
                  values={br()}
                />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="BOSagora focuses on establishing a decentralized
                  {br} blockchain platform which can implement {br}
                  a democratic decision-making process based on Smart Contracts
                  {br} and Congress Network."
                  values={br()}
                />
              </p>
            </li>
          </ul>

          <div className="why-area">
            <h3 className="sub-tit3">
              <FormattedMessage defaultMessage="Why BOSagora" />
            </h3>
            <p className="sub-txt">
              <FormattedMessage
                defaultMessage="Pursuing democracy, legitimacy, fairness,
                transparency {br}and efficiency of the highest standard."
                values={br()}
              />
            </p>
            <picture className="img-why">
              <img src={ImgWhy} className="pc" alt="Why BOSagora" />
              <img src={ImgWhyM} className="m" alt="Why BOSagora" />
            </picture>
            <p>
              <FormattedMessage
                defaultMessage="The BOSagora team aims to implement Smart
                Contracts, which will provide an efficient {br} and safety
                designed contract creation engine in an easy-to-develop
                language with many tools to be used by all."
                values={br()}
              />
            </p>
          </div>

          <div className="demo-area">
            <div className="txt-area">
              <h3 className="sub-tit3">
                <FormattedMessage
                  defaultMessage="VOTERA, {br_m} proves {br} deliberative
                  {br_m} democracy"
                  values={br()}
                />
              </h3>
              <strong>
                <FormattedMessage
                  defaultMessage="A democratic BOA ecosystem {br} that is
                  voluntarily driven by the {br} community"
                  values={br()}
                />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="Even large blockchain projects that claim to advocate
                  decentralization and democracy encounter ‘social or peer
                  pressure’, ‘decisions made by only a few’, ‘public deception’
                  problems in the decision-making process. {br}
                  That’s why we developed VOTERA in which everyone can share an
                  opinion and make decisions harmoniously. Experience honest and
                  clean decision making on Agora Network."
                  values={br()}
                />
              </p>
            </div>
            <div className="img-area">
              <img src={ImgWhyBlog} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec-foundation">
        <div className="container">
          <h3 className="sub-tit3">
            <FormattedMessage defaultMessage="Foundation" />
          </h3>
          <ul className="lst-foundation">
            <li>
              <picture className="img-foundation">
                <img src={ImgWhyFun1} className="pc" alt="Inhwan Kim" />
                <img src={ImgWhyFun1M} className="m" alt="Inhwan Kim" />
              </picture>
              <div className="txt">
                <strong>
                  <FormattedMessage defaultMessage="Inhwan Kim" />
                </strong>
                <p>
                  <span>
                    <FormattedMessage
                      defaultMessage="Inhwan Kim brings over 35 years of
                      marketing experience to the BOSagora team."
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      defaultMessage="During these 35 years Inhwan Kim has
                      been the driving force behind many industry leading
                      brands spanning from IT all the way to traditional finance."
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      defaultMessage="Some of these brands include the stock trading community
                      portal Paxnet and the spring water brand Jeju Samdasu.
                      Inhwan Kim has been apart of the BOSagora Foundation
                      since 2015 where he served as president."
                    />
                  </span>
                </p>
              </div>
            </li>
            <li>
              <picture className="img-foundation">
                <img src={ImgWhyFun2} className="pc" alt="Serge Komaromi" />
                <img src={ImgWhyFun2M} className="m" alt="Serge Komaromi" />
              </picture>
              <div className="txt">
                <strong>
                  <FormattedMessage defaultMessage="Serge Komaromi" />
                </strong>
                <p>
                  <span>
                    <FormattedMessage
                      defaultMessage="Immediately after graduating from law school in Geneva,
                      Switzerland Serge Komaromi joined the international bank UBS
                      where he served as a fund manager."
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      defaultMessage="In 2009 after spending what seemed like a lifetime in
                      traditional banking Serge Komaromi decided to turn his
                      efforts to environmental initiatives in the hopes of
                      contributing to the improvement of the state of our planet.
                      In 2017 Serge Komaromi joined the BOSagora Foundation, and
                      has been in charge of general oversight and legal matters."
                    />
                  </span>
                </p>
              </div>
            </li>
          </ul>

          <h3 className="sub-tit3">Advisor</h3>
          <ul className="lst-foundation">
            <li>
              <picture className="img-foundation">
                <img src={ImgWhyFun3} className="pc" alt="Don Tapscott" />
                <img src={ImgWhyFun3M} className="m" alt="Don Tapscott" />
              </picture>
              <div className="txt">
                <strong>
                  <FormattedMessage defaultMessage="Don Tapscott" />
                </strong>
                <p>
                  <span>
                    <FormattedMessage
                      defaultMessage="Don is the author of international best-seller ‘Blockchain
                    Revolution’, and also he authored or co-authored 15 widely
                    read books all over the world."
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      defaultMessage="In 2015, Thinkers50 named Don as the 2nd most important
                    business thinker in the world."
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      defaultMessage="In 2017, he established The Blockchain Research Institute,
                    the world’s leading think-tank dedicated to understanding
                    blockchain opportunities, challenges and use-cases."
                    />
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="sec-ci bg-w">
        <div className="container">
          <div className="tit-area">
            <h3 className="sub-tit3">
              <FormattedMessage defaultMessage="CI" />
            </h3>
            <strong>
              <span>
                <FormattedMessage
                  defaultMessage="The BOSagora Foundation launched a new brand
                  under the name BOSagora in April 2019."
                />
              </span>
              <span>
                <FormattedMessage
                  defaultMessage='BOSagora is a combination of the
                  words "BOS" and "AGORA", meaning the true birth of democracy.'
                />
              </span>
              <span>
                <FormattedMessage
                  defaultMessage="Agora embodies the vision of a company that
                  leads technology and continuously develops {br}
                  for a better future for all of us."
                  values={br()}
                />
              </span>
            </strong>
            <p>
              <FormattedMessage
                defaultMessage="The BOSagora team aims to grow together with partners,
                and provides a brand guidebook for a convenient process."
              />
            </p>
            <picture className="img-ci">
              <img src={ImgCi} className="pc t" alt="BOSagora" />
              <img src={ImgCiM} className="m" alt="BOSagora" />
            </picture>
            <div className="btn-area">
              <a
                href="/files/BOSagora_BI_branding.pdf"
                className="btn-g white download book"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="CI Brand Book {download}"
                  values={{
                    download: (
                      <em>
                        <FormattedMessage defaultMessage="Download" />
                      </em>
                    ),
                  }}
                />
                <span className="material-symbols-outlined">download</span>
              </a>
              <a
                href="/files/BOSagora_Logo.ai"
                className="btn-g white download ai"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="AI {download}"
                  values={{
                    download: (
                      <em>
                        <FormattedMessage defaultMessage="Download" />
                      </em>
                    ),
                  }}
                />
                <span className="material-symbols-outlined">download</span>
              </a>
              <a
                href="/files/BOSagora_Logo_png.zip"
                className="btn-g white download ai"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="PNG {download}"
                  values={{
                    download: (
                      <em>
                        <FormattedMessage defaultMessage="Download" />
                      </em>
                    ),
                  }}
                />
                <span className="material-symbols-outlined">download</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
