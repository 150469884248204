import { Link as TopLink } from "react-scroll";
import { Link } from "../Link";
import { routesEnum } from "../Routes";
import { FormattedMessage } from "react-intl";
import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="nav-ft">
          <ul className="nav-ft-depth1">
            <li>
              <Link to={routesEnum.developersPage}>
                <FormattedMessage defaultMessage="Developers" />
              </Link>
              <ul className="nav-depth2">
                <li>
                  <Link to={routesEnum.developersPage}>
                    <FormattedMessage defaultMessage="Getting Started" />
                  </Link>
                </li>
                <li>
                  <Link to={routesEnum.agoraChainPage}>
                    <FormattedMessage defaultMessage="Agora Chain" />
                  </Link>
                </li>
                <li>
                  <Link to={routesEnum.toolsPage}>
                    <FormattedMessage defaultMessage="Tools" />
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={routesEnum.validatorPage}>
                <FormattedMessage defaultMessage="Validator" />
              </Link>
              <ul className="nav-depth2">
                <li>
                  <Link to={routesEnum.validatorPage}>
                    <FormattedMessage defaultMessage="Becoming a Validator" />
                  </Link>
                </li>
                <li>
                  <Link to="https://docs.bosagora.org/en/votera/introduction">
                    <FormattedMessage defaultMessage="Votera" />
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={routesEnum.earnPage}>
                <FormattedMessage defaultMessage="Earn" />
              </Link>
              <ul className="nav-depth2">
                <li>
                  <Link to={routesEnum.earnCalculatePage}>
                    <FormattedMessage defaultMessage="Calculate Rewards" />
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={routesEnum.newsListPage}>
                <FormattedMessage defaultMessage="Community" />
              </Link>
              <ul className="nav-depth2">
                <li>
                  <Link to={routesEnum.newsListPage}>
                    <FormattedMessage defaultMessage="News" />
                  </Link>
                </li>
                <li>
                  <Link to={routesEnum.socialsPage}>
                    <FormattedMessage defaultMessage="Socials" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/channel/UCjcTwkskyTmAwHpqv9Oynig">
                    <FormattedMessage defaultMessage="Youtube" />
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="ft-social">
          <h2 className="ft-tit">
            <FormattedMessage defaultMessage="BOSagora Social" />
          </h2>
          <ul className="lst-social">
            <li className="medium">
              <Link to="https://medium.com/bosagora">medium</Link>
            </li>
            <li className="telegram">
              <Link to="https://t.me/bosagora_eng">telegram</Link>
            </li>
            <li className="twitter">
              <Link to="https://twitter.com/bosagora1">twitter</Link>
            </li>
            <li className="youtube">
              <Link to="https://www.youtube.com/channel/UCjcTwkskyTmAwHpqv9Oynig">
                youtube
              </Link>
            </li>
            <li className="github">
              <Link to="https://www.github.com/bosagora">github</Link>
            </li>
          </ul>
        </div>
        <TopLink
          id="scrollUp"
          activeClass="active"
          to="top"
          spy={true}
          smooth={true}
          duration={500}
        >
          <FormattedMessage defaultMessage="Scroll to top" />
        </TopLink>

        <div className="ft-copyright">
          <FormattedMessage defaultMessage="Copyright © 2022 BOSagora Foundation" />
        </div>
      </div>
    </footer>
  );
};
export default React.memo(Footer);
