import React, { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { br, PageWrapper } from "../../components/Containers/PageWrapper";
import { FaqToggleBox } from "../../components/Faq/FaqToggleBox";
import { Tab, Tabs } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { Link } from "../../components/Link";
import { CopyClipboard } from "../../components/CopyClipboard/CopyClipboard";
import { FormattedMessage } from "react-intl";

export const Validator: React.FC = () => {
  const [quickIndex, setQuickIndex] = useState(0);
  const carouselRef = useRef(null);
  const settings: Settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    draggable: false,
    vertical: true,
    initialSlide: quickIndex,
    accessibility: false,
    speed: 300,
    infinite: false,
    afterChange(currentSlide: number) {
      setQuickIndex(currentSlide);
    },
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (carouselRef?.current?.slickGoTo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      carouselRef?.current?.slickGoTo(quickIndex);
    }
  }, [quickIndex]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setQuickIndex(newValue);
  };

  const [value, setValue] = React.useState(0);
  const handleChangeSetting = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="Becoming a" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="VALIDATOR" />
            </h2>
            <p className="txt">
              <FormattedMessage
                defaultMessage='BOSagora contains the vision of BOSagora to
                realize the birth of a true democracy {br} by combining the words
                "BOS", which represents the basic operating system of a computer,
                 and "AGORA", which symbolizes direct democracy.'
                values={br()}
              />
            </p>
          </div>
          <div className="btn-area">
            <HashLink to="#quick-start" smooth className="btn-g white">
              <FormattedMessage defaultMessage="Quick Start" />
              <span className="material-symbols-outlined">arrow_right_alt</span>
            </HashLink>
            <Link
              to="https://www.agorascan.io/validators"
              className="btn-g white"
            >
              <FormattedMessage defaultMessage="Validators Overview" />
              <span className="material-symbols-outlined">arrow_right_alt</span>
            </Link>
          </div>
        </div>
      </section>
      <section className="sec-g sec-validator bg-w">
        <div className="container">
          <h3 className="sec-tit3">
            <FormattedMessage
              defaultMessage="Why should be a {br} validator?"
              values={br()}
            />
          </h3>
          <ul className="lst-validator">
            <li>
              <strong>
                <FormattedMessage defaultMessage="Participate" />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="Each node means a validator, so we can go one
                  step closer to decentralization by running a node. If you're
                  a holder, bring value to your BOA by supporting the health and
                  decentralization of the network, and ensure you have a say in
                  its future."
                />
              </p>
            </li>
            <li>
              <strong>
                <FormattedMessage defaultMessage="Vote your choice" />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="In the event of a chain fork, where two chains
                  emerge with two different sets of rules, running your own node
                  guarantees your ability to choose which set of rules you support.
                  It's up to you to upgrade to new rules and support proposed
                  changes, or not. If you're staking BOA, running your own node
                  allows you to choose your own client, to minimize your risk of
                  slashing and to react to fluctuating demands of the network over
                  time. Staking with a third party forfeits your vote on which
                  client you think is the best choice."
                />
              </p>
            </li>
            <li>
              <strong>
                <FormattedMessage defaultMessage="Decentralization" />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="Centralized cloud servers can provide a lot of
                  computing power, but they provide a target for nation-states or
                  attackers looking to disrupt the network. Network resilience
                  is achieved with more nodes, in geographically diverse locations,
                  operated by more people of diverse backgrounds. As more people
                  run their own node, reliance on centralized points of failure
                  diminishes, making the network stronger."
                />
              </p>
            </li>
            <li>
              <strong>
                <FormattedMessage defaultMessage="Sovereignty" />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="A wallet allows you to take full
                  custody and control of your digital assets by holding the
                  private keys to your addresses, but those keys don't tell you
                  the current state of the blockchain, such as your wallet balance.
                  By default, Wallets typically reach out to a 3rd-party
                  node, such as Infura or Alchemy, when looking up your balances.
                  Running your own node allows you to have your own copy of
                  the BOSagora blockchain."
                />
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section id="quick-start" className="sec-quick bg-w">
        <div className="container">
          <h3 className="sec-tit3">
            <FormattedMessage defaultMessage="QUICK {br} START" values={br()} />
          </h3>
          <div className="slider-wrap">
            <Tabs
              className="quick-tab"
              value={quickIndex}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              aria-label="scrollable force tabs"
            >
              <Tab
                label={<FormattedMessage defaultMessage="1. Checklist" />}
                wrapped={true}
                disableRipple={true}
              />
              <Tab
                label={
                  <FormattedMessage defaultMessage="2. Install Docker Engine" />
                }
                wrapped={true}
                disableRipple={true}
              />
              <Tab
                label={<FormattedMessage defaultMessage="3. Install mainnet" />}
                wrapped={true}
                disableRipple={true}
              />
              <Tab
                label={
                  <FormattedMessage defaultMessage="4. Run an Agora-el & Agora-cl" />
                }
                wrapped={true}
                disableRipple={true}
              />
              <Tab
                label={<FormattedMessage defaultMessage="5. Staking BOA" />}
                wrapped={true}
                disableRipple={true}
              />
              <Tab
                label={
                  <FormattedMessage defaultMessage="6. Run an Agora validator" />
                }
                wrapped={true}
                disableRipple={true}
              />
            </Tabs>
            <Slider
              className="slider slider-for"
              {...settings}
              ref={carouselRef}
            >
              {/* Step 1 */}
              <div className="step-info">
                <h3 className="blind">
                  <FormattedMessage defaultMessage="Checklist" />
                </h3>
                <p className="key">
                  <FormattedMessage
                    defaultMessage="This checklist will help you understand
                    the role of a validator and prepare you for the role."
                  />
                </p>
                <div className="detail-wrap checklist">
                  <div className="step-wrap">
                    <div className="step">
                      <em>
                        <FormattedMessage defaultMessage="Section1" />
                      </em>
                      <strong>
                        <FormattedMessage
                          defaultMessage="Before {br} you start"
                          values={br()}
                        />
                      </strong>
                      <p>
                        <FormattedMessage
                          defaultMessage="Review this section before deciding
                          to proceed with validator setup."
                        />
                      </p>
                    </div>
                    <div className="step">
                      <em>
                        <FormattedMessage defaultMessage="Section2" />
                      </em>
                      <strong>
                        <FormattedMessage
                          defaultMessage="During {br} setup"
                          values={br()}
                        />
                      </strong>
                      <p>
                        <FormattedMessage
                          defaultMessage="Use this as a reference during client
                          setup to check off important steps."
                        />
                      </p>
                    </div>
                    <div className="step">
                      <em>
                        <FormattedMessage defaultMessage="Section3" />
                      </em>
                      <strong>
                        <FormattedMessage
                          defaultMessage="After {br} depositing"
                          values={br()}
                        />
                      </strong>
                      <p>
                        <FormattedMessage
                          defaultMessage="Protect your funds using monitoring software,
                        and learn how to handle different real world scenarios."
                        />
                      </p>
                    </div>
                  </div>

                  <Link
                    to="https://agora-staking.bosagora.org/checklist"
                    className="btn-line link-goto"
                  >
                    <FormattedMessage defaultMessage="See more" />
                    <span className="material-symbols-outlined">
                      arrow_right_alt
                    </span>
                  </Link>
                </div>
              </div>
              {/* Step 2 */}
              <div className="step-info">
                <h3 className="blind">Staking BOA</h3>
                <div className="detail-wrap staking">
                  <p>
                    <FormattedMessage
                      defaultMessage="To run an Agora node, you must first
                      have Docker installed and running. See {here} for
                      instructions on how to install the Docker Engine "
                      values={{
                        here: (
                          <Link
                            to="https://docs.docker.com/engine/install/"
                            className="docker-link"
                          >
                            <FormattedMessage defaultMessage="here" />
                          </Link>
                        ),
                      }}
                    />
                  </p>
                  <Link
                    to="https://docs.docker.com/engine/install/"
                    className="btn-line link-goto"
                  >
                    <FormattedMessage defaultMessage="GO TO" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </Link>
                </div>
              </div>
              {/* Step 3 Install mainnet */}
              <div className="step-info">
                <h3 className="blind">
                  <FormattedMessage defaultMessage="Download mainnet zip file" />
                </h3>
                <p className="key">
                  <FormattedMessage defaultMessage="How to install a node for the Agora Mainnet." />
                </p>
                <div className="detail-wrap download">
                  <div className="step">
                    <Tabs
                      orientation="horizontal"
                      value={value}
                      onChange={handleChangeSetting}
                      aria-label="basic tabs"
                      className="exec_tab"
                      variant="fullWidth"
                    >
                      <Tab label="Linux or MacOS" {...a11yProps(0)} />
                      <Tab label="Windows" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <div className="exec">
                        <dl>
                          <FormattedMessage defaultMessage="For macOS 10.9 or later" />
                          <dd>
                            <pre>
                              {`mkdir agora-chain 
cd agora-chain
/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/bosagora/agora-chain/v0.x.x/agora.sh)"
./agora.sh network mainnet`}
                            </pre>
                          </dd>
                          <CopyClipboard
                            text={`mkdir agora-chain
cd agora-chain
/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/bosagora/agora-chain/v0.x.x/agora.sh)"
./agora.sh network mainnet`}
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                      </div>
                      <div className="link-box">
                        <Link
                          to="https://github.com/bosagora/agora-chain#for-linux-or-macos-users"
                          className="btn-line  link-goto"
                        >
                          <FormattedMessage defaultMessage="GO TO" />
                          <span className="material-symbols-outlined s300">
                            north_east
                          </span>
                        </Link>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="exec">
                        <dl>
                          <FormattedMessage defaultMessage="For 64-bit Winodws7 or later" />
                          <dd>
                            <pre>
                              {`mkdir agora-chain
cd agora-chain
curl -S -L -o agora.bat https://raw.githubusercontent.com/bosagora/agora-chain/v0.x.x/agora.bat
call agora.bat
agora.bat network mainnet`}
                            </pre>
                          </dd>
                          <CopyClipboard
                            text={`mkdir agora-chain
cd agora-chain
curl -S -L -o agora.bat https://raw.githubusercontent.com/bosagora/agora-chain/v0.x.x/agora.bat
call agora.bat
agora.bat network mainnet`}
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                      </div>
                      <div className="link-box">
                        <Link
                          to="https://github.com/bosagora/agora-chain#for-windows-users"
                          className="btn-line  link-goto"
                        >
                          <FormattedMessage defaultMessage="GO TO" />
                          <span className="material-symbols-outlined s300">
                            north_east
                          </span>
                        </Link>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
              {/* Step 4 Run an Agora */}
              <div className="step-info">
                <h3 className="blind">
                  <FormattedMessage defaultMessage="Run an Agora execution & consensus layer" />
                </h3>
                <p className="key">
                  <FormattedMessage defaultMessage="Let’s run an Agora-el & Agora-cl layer" />
                </p>
                <div className="detail-wrap download">
                  <div className="step">
                    <Tabs
                      orientation="horizontal"
                      value={value}
                      onChange={handleChangeSetting}
                      aria-label="basic tabs"
                      className="exec_tab"
                      variant="fullWidth"
                    >
                      <Tab label="Linux or MacOS" {...a11yProps(0)} />
                      <Tab label="Windows" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <div className="exec">
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Initialize the execution layer" />
                          </dt>
                          <dd>
                            <pre>./agora.sh el-node init</pre>
                          </dd>
                          <CopyClipboard
                            text="./agora.sh el-node init"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Run an Agora execution layer (Agora-el)" />
                          </dt>
                          <dd>
                            <pre>./agora.sh el-node run</pre>
                          </dd>
                          <CopyClipboard
                            text="./agora.sh el-node run"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Run an Agora consensus layer (Agora-cl)" />
                          </dt>
                          <dd>
                            <pre>./agora.sh cl-node run</pre>
                          </dd>
                          <CopyClipboard
                            text="./agora.sh cl-node run"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="exec">
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Initialize the execution layer" />
                          </dt>
                          <dd>
                            <pre>agora.bat el-node init</pre>
                          </dd>
                          <CopyClipboard
                            text="agora.bat el-node init"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Run an Agora execution layer (Agora-el)" />
                          </dt>
                          <dd>
                            <pre>agora.bat el-node run</pre>
                          </dd>
                          <CopyClipboard
                            text="agora.bat el-node run"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Run an Agora consensus layer (Agora-cl)" />
                          </dt>
                          <dd>
                            <pre>agora.bat cl-node run</pre>
                          </dd>
                          <CopyClipboard
                            text="agora.bat cl-node run"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
              {/* Step 5 */}
              <div className="step-info">
                <h3 className="blind">Staking BOA</h3>
                <div className="detail-wrap staking">
                  <p>
                    <FormattedMessage
                      defaultMessage="This step is optional but essential if you want to
                    participate in securing the Agora Blockchain, have the right
                    to vote and receive rewards. Create personal keys and
                    prepare deposit data and deposit stake"
                    />
                  </p>
                  <Link
                    to="https://agora-staking.bosagora.org/overview"
                    className="btn-line link-goto"
                  >
                    <FormattedMessage defaultMessage="GO TO" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </Link>
                </div>
              </div>
              {/* Step 6 */}
              <div className="step-info">
                <h3 className="blind">
                  <FormattedMessage defaultMessage="Run an Agora validator (Agora-cl-validator)" />
                </h3>
                <p className="key">
                  <FormattedMessage defaultMessage="Let’s run an Agora validator (Agora-cl-validator)" />
                </p>
                <div className="detail-wrap download">
                  <div className="step">
                    <Tabs
                      orientation="horizontal"
                      value={value}
                      onChange={handleChangeSetting}
                      aria-label="basic tabs"
                      className="exec_tab"
                      variant="fullWidth"
                    >
                      <Tab label="Linux or MacOS" {...a11yProps(0)} />
                      <Tab label="Windows" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <div className="exec">
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Import validator keys" />
                          </dt>
                          <dd>
                            <pre>
                              ./agora.sh validator import &#60;your key stores
                              folder&#62;
                            </pre>
                          </dd>
                          <CopyClipboard
                            text="./agora.sh validator import "
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Run your validator" />
                          </dt>
                          <dd>
                            <pre>./agora.sh validator run</pre>
                          </dd>
                          <CopyClipboard
                            text="./agora.sh validator run"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="exec">
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Import validator keys" />
                          </dt>
                          <dd>
                            <pre>
                              agora.bat validator import &#60;your key stores
                              folder&#62;
                            </pre>
                          </dd>
                          <CopyClipboard
                            text="agora.bat validator import "
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                        <dl>
                          <dt>
                            <FormattedMessage defaultMessage="Run your validator" />
                          </dt>
                          <dd>
                            <pre>agora.bat validator run</pre>
                          </dd>
                          <CopyClipboard
                            text="agora.bat validator run"
                            onlyIcon={true}
                            fontSize="small"
                          />
                        </dl>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sec-faq">
        <div className="container">
          <h3 className="sec-tit3">
            <FormattedMessage defaultMessage="FAQ" />
          </h3>
          <div className="sec-cont">
            <ul className="lst-faq box-over">
              <FaqToggleBox defaultExpand={true}>
                <div className="question">
                  <FormattedMessage defaultMessage="What exactly is a validator?" />
                </div>
                <div className="answer">
                  <FormattedMessage
                    defaultMessage="A validator is a virtual entity that lives on the Agora Chain,
                  represented by a balance, public key, and other properties,
                  and participates in consensus of the Agora network."
                  />
                </div>
              </FaqToggleBox>
              <FaqToggleBox>
                <div className="question">
                  <FormattedMessage defaultMessage="What is a validator client?" />
                </div>
                <div className="answer">
                  <FormattedMessage
                    defaultMessage="A validator client is the software
                  that acts on behalf of the
                  validator by holding and using its private key to make
                  attestations about the state of the chain.
                  {br} single validator client can hold many
                  key pairs, controlling many validators."
                    values={br()}
                  />
                </div>
              </FaqToggleBox>
              <FaqToggleBox>
                <div className="question">
                  <FormattedMessage defaultMessage="How much BOA do I need to stake to become a validator?" />
                </div>
                <div className="answer">
                  <FormattedMessage
                    defaultMessage="Each key-pair associated with a validator requires locking
                  40000 BOA to be activated,
                  {br} which represents your initial balance as
                  well as your initial and maximum voting power for any
                  validator."
                    values={br()}
                  />
                </div>
              </FaqToggleBox>
              <FaqToggleBox>
                <div className="question">
                  <FormattedMessage defaultMessage="Why do I need to have funds at stake?" />
                </div>
                <div className="answer">
                  <FormattedMessage
                    defaultMessage="As a validator, you'll need to have funds at stake so you
                  can be penalized for behaving dishonestly.
                  {br} In other words, to keep you honest, your
                  actions need to have financial consequences."
                    values={br()}
                  />
                </div>
              </FaqToggleBox>
              <FaqToggleBox>
                <div className="question">
                  <FormattedMessage defaultMessage="When should I top up my validator’s balance?" />
                  <span className="ico-plus"></span>
                </div>
                <div className="answer">
                  <FormattedMessage
                    defaultMessage="The answer to this question very much depends on how much BOA
                  you have at your disposal.
                  {br} You should certainly top up if your
                  balance is close to 20000 BOA.
                  {br} This is to ensure you don’t get kicked
                  out of the validator set.
                  {br} At the other end of the spectrum, if
                  your balance is closer to 38000 BOA, it’s probably not worth
                  adding the extra BOA required to get back to 40000."
                    values={br()}
                  />
                </div>
              </FaqToggleBox>
            </ul>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="tabpanel"
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
