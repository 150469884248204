import React from "react";
import { PageWrapper } from "../../components/Containers/PageWrapper";
import { Link } from "../../components/Link";
import { HashLink } from "react-router-hash-link";
import { FormattedMessage } from "react-intl";

export const Tools: React.FC = () => {
  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area et">
            <span className="co">
              <FormattedMessage defaultMessage="Getting Started" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="TOOLS" />
            </h2>
          </div>
        </div>
      </section>

      <section className="sec-g sec-tools">
        <div className="container">
          <nav className="tab- tab-square">
            <ul className="lst-">
              <li>
                <HashLink to="#wallet" smooth>
                  <FormattedMessage defaultMessage="Wallet" />
                </HashLink>
              </li>
              <li>
                <HashLink to="#explorer" smooth>
                  <FormattedMessage defaultMessage="Block Explorer" />
                </HashLink>
              </li>
              <li>
                <HashLink to="#sdk" smooth>
                  <FormattedMessage defaultMessage="SDK" />
                </HashLink>
              </li>
              <li>
                <HashLink to="#ide" smooth>
                  <FormattedMessage defaultMessage="IDE" />
                </HashLink>
              </li>
              <li>
                <HashLink to="#dex" smooth>
                  <FormattedMessage defaultMessage="Exchange" />
                </HashLink>
              </li>
            </ul>
          </nav>

          <div id="wallet" className="sec-area">
            <h3 className="sec-tit2">
              <FormattedMessage defaultMessage="Wallet" />
            </h3>
            <ul className="lst-tools box-over">
              <li className="wallet">
                <Link to="https://metamask.io/download/">
                  <strong>
                    <FormattedMessage defaultMessage="Metamask" />
                  </strong>
                  <span className="link- link-goto">
                    <FormattedMessage defaultMessage="VISIT" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div id="explorer" className="sec-area">
            <h3 className="sec-tit2">
              <FormattedMessage defaultMessage="Block Explorer" />
            </h3>
            <ul className="lst-tools box-over explorer">
              <li>
                <Link to="https://www.boascan.io">
                  <strong>
                    <FormattedMessage defaultMessage="BOAScan" />
                  </strong>
                  <span className="link- link-goto">
                    <FormattedMessage defaultMessage="VISIT" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="https://www.agorascan.io">
                  <strong>AgoraScan</strong>
                  <span className="link- link-goto">
                    <FormattedMessage defaultMessage="VISIT" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div id="sdk" className="sec-area">
            <h3 className="sec-tit2">
              <FormattedMessage defaultMessage="SDK" />
            </h3>
            <ul className="lst-tools box-over">
              <li className="sdk">
                <Link to="https://web3js.readthedocs.io/en/v1.8.0/">
                  <strong>
                    <FormattedMessage defaultMessage="web3 lib" />
                  </strong>
                  <span className="link- link-goto">
                    <FormattedMessage defaultMessage="VISIT" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div id="ide" className="sec-area">
            <h3 className="sec-tit2">
              <FormattedMessage defaultMessage="IDE" />
            </h3>
            <ul className="lst-tools box-over">
              <li className="ide">
                <Link to="https://remix.ethereum.org/">
                  <strong>
                    <FormattedMessage defaultMessage="Remix IDE" />
                  </strong>
                  <span className="link- link-goto">
                    <FormattedMessage defaultMessage="VISIT" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div id="dex" className="sec-area">
            <h3 className="sec-tit2">
              <FormattedMessage defaultMessage="Exchange" />
            </h3>
            <ul className="lst-tools box-over">
              <li className="bosagora">
                <Link to="https://www.boaswap.io">
                  <strong>
                    <FormattedMessage defaultMessage="BOASwap" />
                  </strong>
                  <span className="link- link-goto">
                    <FormattedMessage defaultMessage="VISIT" />
                    <span className="material-symbols-outlined s300">
                      north_east
                    </span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
