import React from "react";
import { br, PageWrapper } from "../../components/Containers/PageWrapper";
import { Link } from "../../components/Link";
import { FormattedMessage } from "react-intl";

export const Developers: React.FC = () => {
  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="Getting Started" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="Start Building" />
            </h2>
          </div>
        </div>
      </section>

      <section className="sec-g sec-validators">
        <div className="container">
          <h3 className="sec-tit2">
            <FormattedMessage defaultMessage="Validators" />
          </h3>
          <Link
            to="https://agora-staking.bosagora.org"
            className="link-validators"
          >
            <strong>
              <FormattedMessage defaultMessage="Validator Start" />
            </strong>
            <span className="link- link-goto">
              <FormattedMessage defaultMessage="Staking" />
              <span className="material-symbols-outlined s300">north_east</span>
            </span>
          </Link>
        </div>
      </section>

      <section className="sec-g sec-develpoers">
        <div className="container">
          <h3 className="sec-tit2">
            <FormattedMessage defaultMessage="Developers" />
          </h3>
          <ul className="lst-develpoers box-over">
            <li>
              <Link to="https://docs.bosagora.org/getting-started/deploy-smart-contract">
                <strong>
                  <FormattedMessage
                    defaultMessage="Deploy {br} Smart Contract"
                    values={br()}
                  />
                </strong>
                <p>
                  <FormattedMessage
                    defaultMessage="How to compile {br}
                    and deploy the developed {br}
                    smart contract."
                    values={br()}
                  />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="Quick Start" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="https://docs.bosagora.org/getting-started/erc-20-tokens">
                <strong>
                  <FormattedMessage
                    defaultMessage="ERC20 {br} Tokens"
                    values={br()}
                  />
                </strong>
                <p>
                  <FormattedMessage
                    defaultMessage="ERC20 Tokens: {br}
                    Easily distribute ERC20 of {br}
                    Solidity Simplicity ~10 minutes."
                    values={br()}
                  />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="Quick Start" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="https://docs.bosagora.org/getting-started/deploy-nfts">
                <strong>
                  <FormattedMessage
                    defaultMessage="Deploy {br} NFTs"
                    values={br()}
                  />
                </strong>
                <p>
                  <FormattedMessage
                    defaultMessage="Estimated time to complete {br}
                    this guide: ~15 minutes."
                    values={br()}
                  />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="Quick Start" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </PageWrapper>
  );
};
