import React, { useEffect, useState } from "react";
import MenuBar from "../MenuBar/MenuBar";
import { Element } from "react-scroll";
import { useWindowSize } from "../../hooks/useWindowSize";
import Footer from "../Footer/Footer";

interface Props {
  children?: React.ReactNode;
  className?: string;
}
export const PageWrapper: React.FC<Props> = ({ children, className }) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrolled, setScrolled] = useState(className);
  const [scrollY, setScrollY] = useState(0);
  const { isRealMobile } = useWindowSize();

  useEffect(() => {
    if (isRealMobile) {
      setShow(true);
      setScrolled(className);
    } else {
      if (window.scrollY > 50 && window.scrollY > lastScrollY) {
        setShow(false);
        setScrolled(className);
      } else {
        setShow(true);
        if (window.scrollY === 0) {
          setScrolled(className);
        } else {
          if (className === "intro") {
            if (window.scrollY > 50) {
              setScrolled("scrolled");
            } else {
              setScrolled(className);
            }
          } else {
            setScrolled("scrolled " + className);
          }
        }
      }
    }
    setLastScrollY(window.scrollY);
  }, [scrollY, isRealMobile]);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        setScrollY(window.scrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, []);

  return (
    <div id="wrap" className={scrolled}>
      <Element name="top" />
      <MenuBar show={show} />
      <main id="main">{children}</main>
      <Footer />
    </div>
  );
};

export const br = () => {
  return {
    br: (
      <>
        <br className="pc" />
        <br className="t" />
      </>
    ),
    br_pc: <br className="pc" />,
    br_t: <br className="t" />,
    br_m: <br className="m" />,
    br_all: <br />,
  };
};
