import React, { useEffect, useState } from "react";
import { PageWrapper } from "../../components/Containers/PageWrapper";
import ReceiveNews from "components/ReceiveNews/ReceiveNews";
import request from "../../api/request";
import { Link } from "../../components/Link";
import { SubscriptEmail } from "../../components/Subscript/SubscriptEmail";
import _ from "lodash";
import {
  CircularProgress,
  Pagination,
  PaginationItem,
  Skeleton,
  SvgIcon,
} from "@mui/material";
// import LeftArrow from "../../assets/images/news/arrow_left.svg";
// import RightArrow from "../../assets/images/news/arrow_right.svg";
import { routesEnum } from "../../components/Routes";
import { FormattedMessage, useIntl } from "react-intl";
import { useWindowSize } from "../../hooks/useWindowSize";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useHistory, useLocation } from "react-router-dom";

export interface PostItem {
  id: string;
  category: string;
  content?: any;
  title: string;
  title_image: string;
  news_date: string;
  visible: boolean;
  created_date: string;
}
interface Props {
  page?: number;
}
export const Community: React.FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();
  const { locale } = useIntl();

  const [postPerPage] = useState(9);
  const [list, setList] = useState<PostItem[] | null>(Array<PostItem>);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const { isMobile } = useWindowSize();
  const [isLastPage, setLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(
    (location.state as Props)?.page ?? 1
  );

  useEffect(() => {
    getPostList();
  }, [history]);

  const handlerOnClickMore = () => {
    if (currentPage) setCurrentPage(currentPage + 1);
  };

  const getPostList = () => {
    setLoading(true);
    request("get", `/news?page=${currentPage}`)
      .then((res: any) => {
        if (res.status === 200) {
          const p = Number(res.data.total) / postPerPage;
          const mod = Number(res.data.total) % postPerPage ? true : false;
          const totalCount = mod ? _.toInteger(p) + 1 : _.toInteger(p);
          setTotalCount(totalCount);
          if (totalCount === currentPage) setLastPage(true);
          if (isMobile) {
            setList(_.concat(list, res.data.data));
          } else {
            setList(res.data.data);
            window.scrollTo(0, 0);
          }
        } else {
          setList(null);
          // TODO : 서비스 오류 얼럿
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        setLoading(false);
        setList([]);
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPostList();
    if (currentPage && currentPage > 0) {
      history.push({
        pathname: `/${locale}${routesEnum.newsListPage}`,
        state: { page: currentPage },
      });
    }
  }, [history, currentPage]);

  const handlerOnChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handlerRenderItem = (item: any) => {
    if (item.type === "previous") {
      return (
        <PaginationItem {...item}>
          <SvgIcon>
            <ArrowCircleLeftIcon />
          </SvgIcon>
        </PaginationItem>
      );
    } else if (item.type === "next") {
      return (
        <PaginationItem {...item}>
          <SvgIcon>
            <ArrowCircleLeftIcon />
          </SvgIcon>
        </PaginationItem>
      );
    }
    return <PaginationItem {...item} />;
  };

  const getPost = (post: PostItem): JSX.Element => {
    const date = post?.news_date.toString();

    return (
      <li key={post.id}>
        <Link to={routesEnum.newsViewPage + post.id}>
          <span className="thumb">
            <img src={post.title_image} alt="" className="img-news" />
          </span>
          <span className="txt-wrap">
            <em className="sort">{post.category}</em>
            <p className="txt">{post.title}</p>
            {date && (
              <span className="date">
                {date.substring(0, 4)}
                {isMobile && "."}
                <span className="md">{`${date.substring(4, 6)}.${date.substring(
                  6,
                  8
                )}`}</span>{" "}
              </span>
            )}
          </span>
        </Link>
      </li>
    );
  };

  const getSkeleton = (): JSX.Element[] => {
    return _.map([1, 2, 3, 4, 5], (value: number) => {
      return (
        <li key={value}>
          <div className="skeleton">
            <Skeleton className="skeletonImg" variant="rectangular" />
            <div className="skeletonText">
              <div className="skBox1">
                <Skeleton className="sk1" />
                <Skeleton className="sk2" />
                <Skeleton className="sk3" />
              </div>
              <div className="skBox2">
                <Skeleton className="sk1" />
                <Skeleton className="sk2" />
              </div>
            </div>
          </div>
        </li>
      );
    });
  };

  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="BOSagora" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="NEWS" />
            </h2>
            <p className="txt">
              <FormattedMessage
                defaultMessage="Enter your email address to subscribe our newsletter
                for news announcements, latest updates, and special events!"
              />
            </p>
          </div>
          <div className="sub-top">
            <SubscriptEmail />
          </div>
        </div>
      </section>

      <section className="sec-g board-lst-wrap">
        <div className="container">
          <ul id="newsList" className="board-lst-cont box-over">
            {list && list.length > 0
              ? list.map((post: PostItem) => getPost(post))
              : getSkeleton()}
          </ul>

          {isMobile ? (
            <button
              className="btn-more"
              onClick={handlerOnClickMore}
              disabled={isLastPage}
            >
              {loading ? <CircularProgress /> : isLastPage ? "No more" : "more"}
            </button>
          ) : (
            <Pagination
              className="pagination"
              count={totalCount}
              size="large"
              page={currentPage}
              onChange={handlerOnChangePage}
              renderItem={handlerRenderItem}
            />
          )}
        </div>
      </section>

      <section className="sec-g sec-inthepress p100">
        <div className="container">
          <h2 className="tit-sub">
            <FormattedMessage defaultMessage="In the PRESS" />
          </h2>
          <ul className="lst-press">
            <li className="coindesk">
              <Link to="https://www.coindesk.com/sponsored-content/bosagoras-t-fi-platform-links-with-binance-smart-chain/">
                coindesk
              </Link>
            </li>
            <li className="cointele">
              <Link to="https://cointelegraph.com/press-releases/bosagora-unveils-t-fi-the-first-real-economy-decentralized-financial-model">
                cointelegraph the future of money
              </Link>
            </li>
            <li className="bloom">
              <Link to="https://www.bloomberg.com/press-releases/2021-02-18/bosagora-unveils-the-t-fi-a-first-real-economy-defi-financial-model">
                Bloomberg
              </Link>
            </li>
            <li className="yahoo">
              <Link to="https://www.yahoo.com/now/bosagora-launches-biznet-exclusive-boaswap-130000140.html">
                yahoo! finance
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <ReceiveNews />
    </PageWrapper>
  );
};
