import React from "react";
import { br, PageWrapper } from "../../components/Containers/PageWrapper";

import PageNotFoundImg from "../../assets/images/404/pageNotFound.png";
import { Link } from "../../components/Link";
import { routesEnum } from "../../components/Routes";
import { FormattedMessage } from "react-intl";

export const NotFoundPage = (): JSX.Element => {
  return (
    <PageWrapper className="intro">
      <section className="sec-areatop bg-blue notFound">
        <div className="container">
          <img className="notImg" src={PageNotFoundImg} />
          <span className="tx1">
            <FormattedMessage defaultMessage="Page not Found" />
          </span>
          <div className="tx2">
            <FormattedMessage
              defaultMessage="We're sorry,{br_m} the page you requested could not be found."
              values={br()}
            />
          </div>
          <span className="tx4">
            <FormattedMessage defaultMessage="Please go back to the homepage." />
          </span>
          <Link to={routesEnum.landingPage}>
            <button className="btn">
              <FormattedMessage defaultMessage="Go back to home" />
            </button>
          </Link>
        </div>
      </section>
    </PageWrapper>
  );
};
