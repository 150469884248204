import React, { useEffect, useState } from "react";
import { PageWrapper } from "../../../components/Containers/PageWrapper";
import ReceiveNews from "../../../components/ReceiveNews/ReceiveNews";
import { routesEnum } from "../../../components/Routes";
import request from "../../../api/request";
import { Skeleton } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { PostItem } from "../index";
import { useWindowSize } from "../../../hooks/useWindowSize";

export const NewsView: React.FC = (props: any) => {
  const [ScrollActive, setScrollActive] = useState(false);
  const articleId = props.match.params["articleId"];
  const [content, setContent] = useState<PostItem>();
  const [date, setDate] = useState<string>();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const { locale } = useIntl();
  const { isRealMobile } = useWindowSize();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    if (!articleId) gotoList();
    request("GET", `/news?id=${articleId}`)
      .then((res: any) => {
        // setLoading(false);
        if (res.status === 200 && res.data.Count > 0) {
          const item = res.data.Items[0];
          setContent(item);
          if (item?.news_date) {
            const d = item?.news_date.toString();
            setDate(
              `${d.substring(0, 4)}-${d.substring(4, 6)}-${d.substring(6, 8)}`
            );
          }
          if (item?.next) {
            setNext(item.next);
          }
          if (item?.previous) {
            setPrevious(item.previous);
          }
        } else {
          gotoList();
        }
      })
      .catch((error: any) => {
        gotoList();
        return error;
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  const gotoList = () => {
    props.history.push(`/${locale}${routesEnum.newsListPage}`);
  };

  useEffect(() => {
    if (scrollY > 464 && !isRealMobile) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  }, [isRealMobile, scrollY]);

  useEffect(() => {
    const handlerScroll = () => {
      if (typeof window !== "undefined") setScrollY(window.scrollY);
    };

    function scrollListener() {
      window.addEventListener("scroll", handlerScroll);
    }
    scrollListener();
    return () => {
      window.removeEventListener("scroll", handlerScroll);
    };
  }, []);

  return (
    <PageWrapper>
      <section className="sec-areatop-sub">
        <div className="container">
          {content?.category ? (
            <div className="history">
              {content?.category?.toUpperCase()} <span>{" / "}</span>
              <FormattedMessage defaultMessage="Official Partner" />
            </div>
          ) : (
            <Skeleton className="history" width="300px" />
          )}
          {content?.title ? (
            <h2 className="subtit">{content?.title}</h2>
          ) : (
            <Skeleton className="subtit" />
          )}
          {date ? (
            <div className="updated">{`Updated ${date}`}</div>
          ) : (
            <Skeleton className="updated" width="100px" />
          )}
        </div>
      </section>

      <section className="sec-g board-view-wrap bg-w">
        <div className="container">
          {content?.content ? (
            <div
              className="txt-wrap"
              dangerouslySetInnerHTML={{ __html: content?.content ?? "" }}
            />
          ) : (
            <div className="txt-wrap">
              <Skeleton height="25px" />
              <Skeleton height="25px" />
              <Skeleton height="50px" />
              <Skeleton height="300px" />
            </div>
          )}
        </div>
        <ul
          className={ScrollActive ? "fixed board-btn-wrap" : "board-btn-wrap"}
        >
          {next && (
            <li>
              <a href="#!" className="link-prev">
                <span className="blind">이전글</span>
                <span className="material-symbols-outlined">arrow_back</span>
              </a>
            </li>
          )}
          <li>
            <button onClick={() => history.back()} className="link-lst">
              <span className="blind">뉴스 목록</span>
              <span className="material-symbols-outlined">apps</span>
            </button>
          </li>
          {previous && (
            <li>
              <a href="#!" className="link-next">
                <span className="blind">다음글</span>
                <span className="material-symbols-outlined">
                  arrow_right_alt
                </span>
              </a>
            </li>
          )}
        </ul>
      </section>
      <ReceiveNews />
    </PageWrapper>
  );
};
