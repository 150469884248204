import { Link } from "../Link";
import React, { useState } from "react";
import { routesEnum } from "../Routes";
import { useWindowSize } from "../../hooks/useWindowSize";
import { MenuList } from "./MenuList";
import { Language } from "./Language/Language";
import { SearchTransaction } from "./Search/SearchTransaction";
import { Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface MenuProp {
  show: boolean;
}
const MenuBar: React.FC<MenuProp> = ({ show }) => {
  const [active, setActive] = useState(false);
  const { isMobile } = useWindowSize();
  const [isOpen, setOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <header id="header" className={active ? "active" : ""}>
      <div className="gnb-overlay-bg"></div>
      <div className="hd" style={{ top: show ? "0px" : "-100px" }}>
        <div className="container">
          <h1 id="logo">
            <Link to={routesEnum.landingPage} className="img-g">
              BOSagora
            </Link>
          </h1>
          <nav id="gnb">
            <h2 className="blind">전체메뉴</h2>
            <button className="label-navham" onClick={toggleDrawer(true)}>
              <span className="blind">Menu</span>
              <div className="nav-ham">
                <div className="inner"></div>
              </div>
            </button>
            <div className="nav-bg"></div>
            {isMobile ? (
              <Drawer
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer(false)}
              >
                <div className="nav-wrap">
                  <div className="util_wrap">
                    <SearchTransaction className="search-m" />
                    <button className="close_btn" onClick={toggleDrawer(false)}>
                      <CloseIcon fontSize="large" />
                    </button>
                  </div>
                  <MenuList setActive={setActive} />
                </div>
              </Drawer>
            ) : (
              <div className="nav-wrap">
                <MenuList setActive={setActive} />
              </div>
            )}

            {!isMobile && <SearchTransaction className="search" />}
            <Language />
          </nav>
        </div>
      </div>
    </header>
  );
};
export default React.memo(MenuBar);
