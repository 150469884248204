import React from "react";
import { PageWrapper } from "../../components/Containers/PageWrapper";
import { addNetwork } from "../../utils/wallet";
import { Link } from "../../components/Link";
import { CopyClipboard } from "../../components/CopyClipboard/CopyClipboard";
import { FormattedMessage } from "react-intl";

export const Agorachain: React.FC = () => {
  const handleAddMetamaskClick = async () => {
    await addNetwork();
  };

  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="Mainnet" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="AGORA chain" />
            </h2>
          </div>
        </div>
      </section>

      <section className="sec-g sec-chain">
        <div className="container">
          <div className="metamask-wrap">
            <button className="btn-metamask" onClick={handleAddMetamaskClick}>
              <FormattedMessage defaultMessage="Add Agora Network to Metamask" />
            </button>
            <div className="chain-metamask">
              <strong>
                <FormattedMessage defaultMessage="Agora Mainnet Information" />
              </strong>
              <dl>
                <dt>
                  <FormattedMessage defaultMessage="Network Name :" />
                </dt>
                <dd>
                  <CopyClipboard text="Agora mainnet" />
                </dd>
              </dl>
              <dl>
                <dt>
                  <FormattedMessage defaultMessage="ChainID :" />
                </dt>
                <dd>
                  <CopyClipboard text="2151" />
                </dd>
              </dl>
              <dl className="column">
                <dt>
                  <FormattedMessage defaultMessage="JSON-RPC URL :" />
                </dt>
                <dd>
                  <CopyClipboard text="https://mainnet.bosagora.org" />
                  <br className="pc" />
                  <CopyClipboard text="https://rpc.bosagora.org" />
                </dd>
              </dl>
              <dl className="et">
                <dt>
                  <FormattedMessage defaultMessage="Currency Symbol :" />
                </dt>
                <dd>
                  <CopyClipboard text="BOA" />
                </dd>
              </dl>
              <dl className="column">
                <dt>
                  <FormattedMessage defaultMessage="BOAScan el explorer :" />
                </dt>
                <dd>
                  <CopyClipboard text="https://boascan.io" />
                </dd>
              </dl>
              <dl className="column">
                <dt>
                  <FormattedMessage defaultMessage="AgoraScan cl explorer :" />
                </dt>
                <dd>
                  <CopyClipboard text="https://agorascan.io" />
                </dd>
              </dl>
            </div>
          </div>
          <ul className="lst-chain box-over">
            <li className="blue fst">
              <Link to="https://boascan.io/">
                <strong>
                  <FormattedMessage defaultMessage="BOAScan" />
                </strong>
                <p>
                  <FormattedMessage defaultMessage="EVM based transaction, smart contract engine" />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="GO TO" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
            <li className="blue">
              <Link to="https://agorascan.io/">
                <strong>
                  <FormattedMessage defaultMessage="AgoraScan" />
                </strong>
                <p>
                  <FormattedMessage defaultMessage="POS validators staked BOA" />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="GO TO" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
            <li className="fst">
              <Link to="https://docs.bosagora.org/validator-start/running-an-agora-node-and-validator/agora-mainnet">
                <strong>
                  <FormattedMessage defaultMessage="Agora mainnet" />
                </strong>
                <p>
                  <FormattedMessage defaultMessage="You can start an Agora mainnet node." />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="GO TO" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="https://docs.bosagora.org/validator-start/running-an-agora-node-and-validator/agora-testnet">
                <strong>
                  <FormattedMessage defaultMessage="Agora testnet" />
                </strong>
                <p>
                  <FormattedMessage defaultMessage="You can start an Agora testnet node." />
                </p>
                <span className="link- link-goto">
                  <FormattedMessage defaultMessage="GO TO" />
                  <span className="material-symbols-outlined s300">
                    north_east
                  </span>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </PageWrapper>
  );
};
