import { Link } from "../Link";
import { routesEnum } from "../Routes";
import { FormattedMessage } from "react-intl";
import { MenuItem } from "./MenuItem";
import React from "react";

interface MenuList {
  setActive: Function;
}

export const MenuList: React.FC<MenuList> = ({ setActive }) => {
  return (
    <ul className="gnb-1dep">
      <li className="m">
        <Link to={routesEnum.landingPage} className="arr-none">
          <FormattedMessage defaultMessage="Home" />
        </Link>
      </li>

      <MenuItem setActive={setActive}>
        <button>
          <span>
            <FormattedMessage defaultMessage="Ecosystem" />
          </span>
        </button>
        <div className="sub">
          <ul className="gnb-2dep m1">
            <li>
              <Link to={routesEnum.ecoSystemPage}>
                <FormattedMessage defaultMessage="Ecosystem" />
              </Link>
            </li>
            <li>
              <Link to={routesEnum.K2EPage}>
                <FormattedMessage defaultMessage="K2E" />
              </Link>
            </li>
            <li>
              <Link to={routesEnum.LaasPage}>
                <FormattedMessage defaultMessage="LaaS" />
              </Link>
            </li>
          </ul>
        </div>
      </MenuItem>

      <MenuItem setActive={setActive}>
        <button>
          <span>
            <FormattedMessage defaultMessage="Developers" />
          </span>
        </button>
        <div className="sub">
          <ul className="gnb-2dep m2">
            <li>
              <Link to={routesEnum.developersPage}>
                <FormattedMessage defaultMessage="Getting Started" />
              </Link>
            </li>
            <li>
              <Link to={routesEnum.agoraChainPage}>
                <FormattedMessage defaultMessage="Agora Chain" />
              </Link>
            </li>
            <li>
              <Link to={routesEnum.toolsPage}>
                <FormattedMessage defaultMessage="Tools" />
              </Link>
            </li>
          </ul>
        </div>
      </MenuItem>
      <MenuItem setActive={setActive}>
        <button>
          <span>
            <FormattedMessage defaultMessage="Validator" />
          </span>
        </button>
        <div className="sub">
          <ul className="gnb-2dep m3">
            <li>
              <Link to={routesEnum.validatorPage}>
                <FormattedMessage defaultMessage="Becoming a Validator" />
              </Link>
              <ul className="gnb-3dep">
                <li>
                  <Link to="https://agora-staking.bosagora.org/checklist">
                    <FormattedMessage defaultMessage="- Checklist" />
                  </Link>
                </li>
                <li>
                  <Link to="https://docs.bosagora.org/validator-start/running-an-agora-node-and-validator/agora-mainnet">
                    <FormattedMessage defaultMessage="- Install an Agora node" />
                  </Link>
                </li>
                <li>
                  <Link to="https://agora-staking.bosagora.org/overview">
                    <FormattedMessage defaultMessage="- Staking over 40,000 BOA" />
                  </Link>
                </li>
                <li>
                  <Link to="https://docs.bosagora.org/validator-start/running-an-agora-node-and-validator/agora-mainnet#import-validator-keys">
                    <FormattedMessage defaultMessage="- Run a Validator Client" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.agorascan.io/validators/eth1deposits">
                    <FormattedMessage defaultMessage="- Deposits" />
                  </Link>
                </li>
                <li>
                  <Link to="https://docs.bosagora.org/upgrades/the-withdrawals-upgrade-for-mainnet">
                    <FormattedMessage defaultMessage="- The withdrawals upgrade" />
                  </Link>
                </li>
                <li>
                  <Link to="https://agora-staking.bosagora.org/faq">
                    <FormattedMessage defaultMessage="- FAQ" />
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#!">
                <FormattedMessage defaultMessage="VOTERA" />
              </a>
              <ul className="gnb-3dep">
                <li>
                  <Link to="https://docs.bosagora.org/en/votera/introduction">
                    <FormattedMessage defaultMessage="- What is VOTERA" />
                  </Link>
                </li>
                {/* <li>
                            <a href="#!">- Participate</a>
                          </li>
                          <li>
                            <a href="#!">- Suggestion list</a>
                          </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </MenuItem>
      <MenuItem setActive={setActive}>
        <button>
          <span>
            <FormattedMessage defaultMessage="Earn" />
          </span>
        </button>
        <div className="sub">
          <ul className="gnb-2dep m4">
            <li>
              <Link to={routesEnum.earnPage}>
                <FormattedMessage defaultMessage="Rewards" />
              </Link>
            </li>
          </ul>
        </div>
      </MenuItem>
      <MenuItem setActive={setActive}>
        <button>
          <span>
            <FormattedMessage defaultMessage="Community" />
          </span>
        </button>
        <div className="sub">
          <ul className="gnb-2dep m5">
            <li>
              <Link to={routesEnum.newsListPage}>
                <FormattedMessage defaultMessage="News" />
              </Link>
            </li>
            <li>
              <Link to={routesEnum.socialsPage}>
                <FormattedMessage defaultMessage="Socials" />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.youtube.com/channel/UCjcTwkskyTmAwHpqv9Oynig"
                className="arr"
              >
                <FormattedMessage defaultMessage="YouTube" />
              </Link>
            </li>
          </ul>
        </div>
      </MenuItem>
      <li>
        <Link to={routesEnum.aboutPage} className="none arr-none">
          <span>
            <FormattedMessage defaultMessage="About" />
          </span>
        </Link>
      </li>
      <li>
        <Link to={routesEnum.contactPage} className="none arr-none">
          <span>
            <FormattedMessage defaultMessage="Contact" />
          </span>
        </Link>
      </li>
    </ul>
  );
};
