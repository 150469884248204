import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import _ from "lodash";

const BOA_SCAN_URL = process.env.REACT_APP_SCAN_URL || undefined;

interface SearchTransactionProp {
  className?: string;
}
export const SearchTransaction: React.FC<SearchTransactionProp> = ({
  className,
}) => {
  const [txId, setTxId] = useState("");
  const { formatMessage } = useIntl();

  const handlerTxClick = () => {
    if (!BOA_SCAN_URL) return;
    if (_.isEmpty(txId)) {
      window.open(BOA_SCAN_URL, "_blank", "noopener,noreferrer");
    } else {
      window.open(BOA_SCAN_URL + "tx/" + txId, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <form className={className} action="">
      <fieldset>
        <legend className="blind">
          <FormattedMessage defaultMessage="Search" />
        </legend>
        <div className="search-area">
          <input
            type="text"
            className="input-search"
            placeholder="BOA TXid"
            value={txId}
            onChange={(e) => {
              setTxId(e.target.value);
            }}
          />
          <button
            id="btn-search"
            className="btn-search"
            title={formatMessage({ defaultMessage: "Search" })}
            onClick={handlerTxClick}
          >
            <span className="blind">
              <FormattedMessage defaultMessage="Search" />
            </span>
          </button>
        </div>
      </fieldset>
    </form>
  );
};
