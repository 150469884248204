import { useCallback, useEffect, useState } from "react";

export const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);
  const [isMobile, setMobile] = useState<boolean>();
  const [isRealMobile, setRealMobile] = useState<boolean>();
  const [isDesktop, setDesktop] = useState<boolean>();
  // const [isTablet, setTablet] = useState<boolean>();

  const handlerResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    if (window.innerWidth > 1024) {
      setDesktop(true);
      setMobile(false);
      setRealMobile(false);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
      setDesktop(false);
      setMobile(true);
      setRealMobile(false);
    } else {
      setDesktop(false);
      setMobile(true);
      setRealMobile(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handlerResize);
      handlerResize();
      return () => window.removeEventListener("resize", handlerResize);
    } else {
      return () =>
        window.removeEventListener("resize", () => {
          return null;
        });
    }
  }, [window]);

  return { windowWidth, windowHeight, isMobile, isDesktop, isRealMobile };
};
