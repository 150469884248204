import React from "react";
import { br, PageWrapper } from "../../components/Containers/PageWrapper";
import ImgEco from "../../assets/images/sub/img-eco.png";
import ImgEcoM from "../../assets/images/sub/img-eco-m.png";
import EcoStaking from "../../assets/images/sub/img-eco-staking.png";
import EcoBuilding from "../../assets/images/sub/img-eco-building.png";
import { Link } from "../../components/Link";
import { routesEnum } from "../../components/Routes";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "@mui/material";

export const Ecosystem: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="Everyday" />
            </span>
            <span className="co" style={{ paddingLeft: 60, paddingTop: 10 }}>
              <FormattedMessage defaultMessage="Blockchain" />
            </span>
            <h2 className="subtit">
              <FormattedMessage defaultMessage="Ecosystem" />
            </h2>
            <p className="txt">
              <FormattedMessage
                defaultMessage="Be a part of Everyday Blockchain Platform.
                {br} The more you use, the more you earn!"
                values={br()}
              />
            </p>
          </div>
        </div>
      </section>

      <section className="sec-eco">
        <div className="container">
          <picture className="img-eco">
            <img src={isMobile ? ImgEcoM : ImgEco} alt="BOASWAP Ecosystem" />
          </picture>
        </div>
      </section>

      <section className="sec-dream">
        <h3 className="sub-tit3">
          <FormattedMessage defaultMessage="Network of Dream" />
        </h3>
        <strong>
          <FormattedMessage defaultMessage="Network for the most perfect you." />
        </strong>
        <p>
          <FormattedMessage
            defaultMessage="A space that contains all the possibilities of the
            space you have been thinking about, {br_all}
            We present a 'Network of Dream' that can connect everyday and
            blockchain networks. {br_all}
            BOSagora is based on the philosophy of deliberative democracy and
            technology {br_all}
            We present a network connected to the everyday that everyone dreams of."
            values={br()}
          />
        </p>
      </section>
      <section className="sec-start">
        <ul className="lst-start">
          <li>
            <Link to={routesEnum.earnPage}>
              <img src={EcoStaking} alt="" />
              <p className="txt">
                <em>
                  <FormattedMessage defaultMessage="Token Holders" />
                </em>
                <strong>
                  <FormattedMessage defaultMessage="Start staking" />
                  <span className="material-symbols-outlined">
                    arrow_right_alt
                  </span>
                </strong>
              </p>
            </Link>
          </li>
          <li>
            <Link to={routesEnum.developersPage}>
              <img src={EcoBuilding} alt="" />
              <p className="txt">
                <em>
                  <FormattedMessage defaultMessage="Developers" />
                </em>
                <strong>
                  <FormattedMessage defaultMessage="Start building" />
                  <span className="material-symbols-outlined">
                    arrow_right_alt
                  </span>
                </strong>
              </p>
            </Link>
          </li>
        </ul>
      </section>
    </PageWrapper>
  );
};
