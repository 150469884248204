import React from "react";
import { br, PageWrapper } from "../../../components/Containers/PageWrapper";
import Img_K2E1 from "../../../assets/images/sub/k2e_1.png";
import Img_K2E2 from "../../../assets/images/sub/k2e_2.png";
import Img_K2E1_m from "../../../assets/images/sub/k2e_1_m.png";
import Img_K2E2_m from "../../../assets/images/sub/k2e_2_m.png";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "@mui/material";

export const K2E: React.FC = () => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="Kiosk to Earn" />
            </span>
            <h2 className="subtit" style={{ paddingLeft: 90 }}>
              <FormattedMessage defaultMessage="K2E" />
            </h2>
            <p className="txt" style={{ paddingLeft: 90 }}>
              <FormattedMessage
                defaultMessage="Experience the power of blackchain rewards at your fingertips,
                {br} unlocking it everywhere, EVERYDAY."
                values={br()}
              />
            </p>
          </div>
        </div>
      </section>

      <section className="sec-k2e">
        <div className="container">
          <picture className="img-k2e">
            <img
              src={isMobile ? Img_K2E1_m : Img_K2E1}
              className="mb-20 img1"
              alt="BOASWAP K2E"
            />
            <img
              src={isMobile ? Img_K2E2_m : Img_K2E2}
              className="img2"
              alt="BOASWAP K2E"
            />
          </picture>
          <div className="info">
            <p>
              <FormattedMessage
                defaultMessage="K2E stands for Kiosk to Earn, which is a business
            model that allows crypto-lovers to earn additional bonuses or {br_pc}
            rewards by utilizing kiosks, also known as payment terminals.
            With the K2E model, crypto-lovers are allowed to {br_pc}
            earn THE9 loyalty points by making purchases or using services
            at kiosks located in their vicinity. "
                values={br()}
              />
            </p>
          </div>
        </div>
      </section>

      <section className="sec-why bg-w">
        <div className="container">
          <strong className="sub-tit3">
            <FormattedMessage defaultMessage="Why K2E?" />
          </strong>
          <div className="items">
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Seamless {br_pc}Connectivity",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "No boundaries while linking {br_pc}on/off-chain by Agora network",
                },
                { br_pc: br().br_pc }
              )}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Vast{br_pc} User Base ",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Massive global user base that keeps expanding, including but not limited to 300 million Chinese users.",
              })}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Rapid {br_pc}expansion",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Exponential growth expanding across multiple continents, including Asia and Europe, with ongoing projects in various countries. ",
              })}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Huge Potential {br_pc}for Growth",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "1 Million kiosks with 1 Billion users {br_pc}" +
                    "projected by 2029",
                },
                { br_pc: br().br_pc }
              )}
            />
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
const WhyItem = React.memo(
  ({ title, content }: { title?: any; content?: any }) => {
    return (
      <div className="why-item">
        <div className="title-area">
          <div className="divider" />
          <p className="title">{title}</p>
        </div>
        <div className="content">{content}</div>
      </div>
    );
  }
);
WhyItem.displayName = "WhyItem";
