import React from "react";
import { br, PageWrapper } from "../../../components/Containers/PageWrapper";
import Img_LAAS1 from "../../../assets/images/sub/laas_1.png";
import Img_LAAS1_m from "../../../assets/images/sub/laas_1_m.png";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "@mui/material";

export const LAAS: React.FC = () => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <PageWrapper>
      <section className="sec-areatop">
        <div className="container">
          <div className="subtit-area">
            <span className="co">
              <FormattedMessage defaultMessage="Loyalty as a Service" />
            </span>
            <h2 className="subtit" style={{ paddingLeft: 90 }}>
              <FormattedMessage defaultMessage="LaaS" />
            </h2>
            <p className="txt" style={{ paddingLeft: 90 }}>
              <FormattedMessage
                defaultMessage="Platform designed to bring blockchain technology into real-life applications
                                through a suite of APIs designed for the easy creation and application of decentralized points and tokens
                                {br}"
                values={br()}
              />
            </p>
          </div>
        </div>
      </section>

      <section className="sec-k2e">
        <div className="container">
          <picture className="img-k2e">
            <img
              src={isMobile ? Img_LAAS1_m : Img_LAAS1}
              className="img2"
              alt="BOASWAP LAAS"
            />
          </picture>
          <div className="info">
            <p></p>
          </div>
        </div>
      </section>

      <section className="sec-why bg-w">
        <div className="container">
          <div className="items2">
            <FeatureItem
              title={formatMessage(
                {
                  defaultMessage: "Easy to Collect Points",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "LaaS allows collecting points easy with just your phone number without needing to install a mobile app. Your number is hashed and anonymized to protect your privacy.",
                },
                { br_pc: br().br_pc }
              )}
            />
            <FeatureItem
              title={formatMessage(
                {
                  defaultMessage: "Easy Smart Contract Configuration",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Through our user-friendly APIs, businesses can seamlessly configure and operate smart contracts, integrating core logic into their systems with ease.",
              })}
            />
          </div>
          <div className="items2">
            <FeatureItem
              title={formatMessage(
                {
                  defaultMessage: "Speed and Transaction Convenience",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "Utilizing a sidechain, LaaS achieves speeds of over 10,000 Transactions Per Second (TPS). Transactions can be conducted on the sidechain without requiring a native $BOA coin, ensuring swift and efficient operations.",
                },
                { br_pc: br().br_pc }
              )}
            />
            <FeatureItem
              title={formatMessage(
                {
                  defaultMessage: "Bridge for Transferring Assets",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "A dedicated bridge facilitates the smooth movement of token assets between the mainnet and sidechain.",
              })}
            />
          </div>
          <div className="items2">
            <FeatureItem
              title={formatMessage(
                {
                  defaultMessage: "Scalability",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Designed with scalability in mind, LaaS allows points and tokens to be applied across various industries, from retail to supply chain management.",
              })}
            />
            <FeatureItem
              title={formatMessage(
                {
                  defaultMessage: "Integrated Ecosystem",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "LaaS supports the formation of an integrated ecosystem through Agora mainnet’s BIP-20 token swaps. This integration fosters a cohesive and interconnected environment for businesses and users alike.",
              })}
            />
          </div>
        </div>
      </section>

      <section className="sec-why bg-w">
        <div className="container">
          <strong className="sub-tit3">
            <FormattedMessage defaultMessage="Collaborating Partners" />
          </strong>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "40px",
              color: "#666666",
            }}
          >
            <FormattedMessage defaultMessage="BOSagora is proud to collaborate with pioneering organizations that are embracing the future of Web3.0 with user-centric business models utilizing decentralized points and tokens based on LaaS" />
          </div>
          <div className="items">
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company A",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "Operating in the Philippines, aiming to run a loyalty business using points and tokens to secure real users with blockchain assets.",
                },
                { br_pc: br().br_pc }
              )}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company B",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Transforming the loyalty and food supply chain sectors in franchise businesses with points and tokens.",
              })}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company C",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Targeting decentralized point businesses without issuing blockchain tokens, planning to operate an integrated point business",
              })}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company D",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "In the pet industry, introducing tokens and points to enhance customer loyalty and transform the market, including the value chain.",
                },
                { br_pc: br().br_pc }
              )}
            />
          </div>
          <div className="items">
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company E",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage(
                {
                  defaultMessage:
                    "Centered around payment terminals like kiosks, running a decentralized point business.",
                },
                { br_pc: br().br_pc }
              )}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company F",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Promoting trade among global member companies by introducing points.",
              })}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "Company G",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Differentiating by introducing points in employee-related areas such as welfare points and electronic meal tickets. ",
              })}
            />
            <WhyItem
              title={formatMessage(
                {
                  defaultMessage: "more",
                },
                { br_pc: br().br_pc }
              )}
              content={formatMessage({
                defaultMessage:
                  "Additional businesses are in discussions to adopt decentralized points and tokens in preparation for the Web3.0 era.",
              })}
            />
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
const WhyItem = React.memo(
  ({ title, content }: { title?: any; content?: any }) => {
    return (
      <div className="why-item">
        <div className="title-area">
          <div className="divider" />
          <p className="title">{title}</p>
        </div>
        <div className="content">{content}</div>
      </div>
    );
  }
);
WhyItem.displayName = "WhyItem";

const FeatureItem = React.memo(
  ({ title, content }: { title?: any; content?: any }) => {
    return (
      <div className="why-item2" style={{ background: "#f8f8f8" }}>
        <div className="title-area">
          <div className="divider" />
          <p className="title">{title}</p>
        </div>
        <div className="content">{content}</div>
      </div>
    );
  }
);

FeatureItem.displayName = "FeatureItem";
