import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

export const Language = () => {
  const { locale } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const handlerLangClick = (e: any) => {
    if (location.pathname.includes(locale)) {
      const path = location.pathname.replace(locale, e.target.value);
      history.push(path);
    } else {
      history.push(`/${e.target.value}`);
    }
  };

  return (
    <div id="lang">
      <input type="checkbox" id="selLang" className="input-lang m" />
      <label htmlFor="selLang" className="label-lang">
        <div className="btn-lang">
          <span className="blind">selected Language</span>
          <span className="material-symbols-outlined">language</span>
        </div>
      </label>
      <ul className="lst-lang">
        <li>
          <button
            value="en"
            className={locale == "en" ? "selected" : undefined}
            onClick={handlerLangClick}
          >
            EN
          </button>
        </li>
        <li>
          <button
            value="ko"
            className={locale == "ko" ? "selected" : undefined}
            onClick={handlerLangClick}
          >
            KR
          </button>
        </li>
      </ul>
    </div>
  );
};
