import React from "react";
import { SubscriptEmail } from "../Subscript/SubscriptEmail";
import { FormattedMessage } from "react-intl";

const ReceiveNews = () => {
  return (
    <section className="sec-g sec-receive-news bg-gray">
      <div className="container">
        <p className="txt">
          <FormattedMessage
            defaultMessage="Enter your email address {br}to receive news."
            values={{ br: <br /> }}
          />
        </p>
        <SubscriptEmail />
      </div>
    </section>
  );
};
export default React.memo(ReceiveNews);
