import React from "react";
import { br, PageWrapper } from "../../components/Containers/PageWrapper";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { FormMail } from "components/FormMail/FormMail";
import { FormattedMessage } from "react-intl";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  // bgcolor: "#f5f4f6",
  boxShadow: 24,
  p: 10,
};

export const Contact: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <PageWrapper>
      <section className="sec-areatop contact">
        <div className="container">
          <div className="subtit-area">
            <h2 className="subtit">
              <FormattedMessage defaultMessage="CONTACT" />
            </h2>
            <p className="txt">
              <FormattedMessage defaultMessage="Get in touch with the BOSagora team." />
            </p>
          </div>
        </div>
      </section>

      <section className="sec-contact">
        <div className="container">
          <ul className="lst-contact box-over">
            <li>
              <Button onClick={handleOpen} className="link-contact">
                <span className="img-g general"></span>
                <strong>
                  <FormattedMessage defaultMessage="General Inquiries" />
                </strong>
                <p>
                  <span>
                    <FormattedMessage defaultMessage="Are you looking for the BOSagora support?" />
                  </span>
                  <span>
                    <FormattedMessage defaultMessage="Fill out the form with your questions or inquiries here." />
                  </span>
                </p>
                <em className="contact">
                  <span className="m">
                    <FormattedMessage defaultMessage="Contact us" />
                    <span className="material-symbols-outlined">
                      arrow_right_alt
                    </span>
                  </span>
                </em>
              </Button>
            </li>
            <li>
              <Button onClick={handleOpen} className="link-contact">
                <span className="img-g partner"></span>
                <strong>
                  <FormattedMessage defaultMessage="Partnerships" />
                </strong>
                <p>
                  <span>
                    <FormattedMessage defaultMessage="Be a part of the BOSagora ecosystem." />
                  </span>
                  <span>
                    <FormattedMessage defaultMessage="We can help you get the most out of our ecosystem." />
                  </span>
                </p>
                <em className="contact">
                  <span className="m">
                    <FormattedMessage defaultMessage="Contact us" />
                    <span className="material-symbols-outlined">
                      arrow_right_alt
                    </span>
                  </span>
                </em>
              </Button>
            </li>
          </ul>
        </div>
      </section>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal-field"
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="tit-wrap">
              <strong id="transition-modal-title">
                <FormattedMessage
                  defaultMessage="The easiest {br_all} way to Funding"
                  values={br()}
                />
              </strong>
              <p>
                <FormattedMessage
                  defaultMessage="We'd love to hear from you {br_all}
                  the most of our ecosystem for you."
                  values={br()}
                />
              </p>
            </div>
            <FormMail setOpen={setOpen} />
            <button onClick={handleClose} className="btn-close">
              close
            </button>
          </Box>
        </Fade>
      </Modal>
    </PageWrapper>
  );
};
